import ApiService from "./api.service";
import { WECHAT_APP_ID } from "@/config/config.local";

export function getAuthUrl(callback_url, state = "h5") {
  const gateway = "https://open.weixin.qq.com/connect/oauth2/authorize";
  const redirect_uri = encodeURIComponent(callback_url);
  return `${gateway}?appid=${WECHAT_APP_ID}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`;
}

export function wechatAuth(code, state, union_session_id = "") {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/auth/wechatAuth", {
      code,
      state,
      union_session_id,
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function signaturePack(url) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/auth/getWechatSignaturePack", { url })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function prepareWechatRecharge(amount) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/pay/prepareRecharge", { amount })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function prepareIcbcRecharge(amount) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/pay/prepareIcbcRecharge", { amount })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}
